import {RoleEnum} from '@core/security/enum/role.enum';

export const environment = {
    debug: false,
    production: true,
    gitVersion: true,
    mercureHub: 'mercure',
    role: RoleEnum.RoleTest,
    analytics: [
        {
            domain: 'boomonderwijs.nl',
            code: 'G-57JRRZQBNL',
        },
        {
            domain: 'boomdigitaal.nl',
            code: 'G-XBC6N280K9',
        },
        {
            domain: 'boomacademie.nl',
            code: 'G-YPPF9DW2Z1',
        },
    ],
    archiveUrl: 'https://test-archief.boomonderwijs.nl',
    searchBooksEnabled: true,
};
